$(function() {
    $('#polec-email').on('click', function() {
        var captcha = $('img.captcha-img');
        var config = captcha.data('refresh-config');
        $.ajax({
            method: 'GET',
            url: '/get_captcha/' + config,
        }).done(function(response) {
            captcha.prop('src', response);
        });
    });
    $('.category_button').on('click', function() {
        $('#not-logged').modal('show')
    });
});
